import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useFitContainer } from '@/hooks/useFitContainer'
import { Fader } from '@/components/Fader'
import { Img } from '@/components/Img'
import { Section } from '@/components/Section'

import variants from './Hero.motion.js'
import * as styles from './Hero.styles.scss'
import defaultImage from './images/bald.jpg'

const Hero = ({ slides, prev }) => {
  const hasSlides = !!slides.length
  const heroRef = useRef()
  const size = useFitContainer(heroRef, 2488, 2000)

  return (
    <Section className={styles.Hero} paddingBottom={false} prev={prev}>
      <motion.div
        className={styles.inner}
        variants={variants.inner}
        initial="hidden"
        animate="visible"
      >
        {hasSlides ? (
          <Fader controls={!!slides.length > 1}>
            {slides.map((item) => (
              <div key={item.id} className={styles.slide}>
                <Img {...item.image} isFull objectFit="cover" />
              </div>
            ))}
          </Fader>
        ) : (
          <div className={styles.staticLayout} ref={heroRef}>
            {size?.width && (
              <div
                className={styles.image}
                style={{
                  width: `${size.width}px`,
                  height: `${size.height}px`,
                }}
              >
                <Img src={defaultImage} isFull objectFit="cover" />
              </div>
            )}
          </div>
        )}
      </motion.div>
      <motion.div
        className={styles.wipe}
        variants={variants.wipe}
        initial="hidden"
        animate="visible"
      />
    </Section>
  )
}

Hero.defaultProps = {
  prev: undefined,
  slides: [],
}

Hero.propTypes = {
  prev: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.object,
      link: PropTypes.object,
    })
  ),
}

export { Hero }
