import { get } from '@/utils/get'

// usage
// getImage(object, 'path', 'secondaryPath')

export const getImage = (object, path, secondaryPath = 'fluid') => {
  const data = get(object, path)

  if (data) {
    const imageObject = get(data, secondaryPath) || {}
    const width = get(data, 'dimensions.width') || 0
    const height = get(data, 'dimensions.height') || 0
    const aspectRatio = width / height

    if (get(data, 'url')) {
      return {
        src: imageObject.src || get(data, 'url'),
        srcset: imageObject.srcSet || null,
        sizes: imageObject.sizes || null,
        aspectRatio: aspectRatio || null,
        placeholderSrc: imageObject.base64 || null,
        alt: data.alt,
        width,
        height,
      }
    }
  }

  return undefined
}
