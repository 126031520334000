import React, { useState, useRef } from 'react'
import { motion } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import PropTypes from 'prop-types'
import useInterval from '@/hooks/useInterval'
import * as styles from './Fader.styles.scss'
import variants from './Fader.motion'

const Fader = ({
  autoplay,
  autoplayDelay,
  children,
  controls,
  duration,
  isVisible,
  onChange,
}) => {
  const [slide, setSlide] = useState(0)
  const [isRunning] = useState(autoplay)
  const totalSlides = React.Children.count(children)
  const imageIndex = wrap(0, totalSlides, slide)
  const isAnimating = useRef(false)

  const paginate = (newDirection) => {
    if (!isAnimating.current) {
      const nextSlide = slide + newDirection
      onChange(wrap(0, totalSlides, nextSlide))
      setSlide(nextSlide)
    }
  }

  useInterval(() => paginate(1), isRunning ? autoplayDelay : null)

  return (
    <div className={styles.el} style={{ opacity: isVisible ? 1 : 0 }}>
      <div className={styles.wrapper}>
        {React.Children.map(children, (child, index) => (
          <motion.div
            key={`slide-${index}`}
            className={styles.item}
            variants={variants}
            initial="initial"
            animate={imageIndex === index ? 'visible' : 'hidden'}
            custom={duration}
            onAnimationStart={() => {
              isAnimating.current = true
            }}
            onAnimationComplete={() => {
              isAnimating.current = false
            }}
          >
            {React.cloneElement(child, {
              /* style: { ...child.props.style, opacity: 0 }, */
            })}
          </motion.div>
        ))}
      </div>
      {controls && (
        <div>
          <button
            type="button"
            onClick={() => paginate(1)}
            data-direction="next"
            className={styles.navigate}
          >
            ‣
          </button>
          <button
            type="button"
            onClick={() => paginate(-1)}
            data-direction="prev"
            className={styles.navigate}
          >
            ‣
          </button>
        </div>
      )}
    </div>
  )
}

Fader.defaultProps = {
  autoplay: false,
  autoplayDelay: 2000,
  children: undefined,
  controls: true,
  duration: 1000,
  isVisible: true,
  onChange: () => {},
}

Fader.propTypes = {
  autoplay: PropTypes.bool,
  autoplayDelay: PropTypes.number,
  children: PropTypes.node,
  controls: PropTypes.bool,
  duration: PropTypes.number,
  isVisible: PropTypes.bool,
  onChange: PropTypes.func,
}

export { Fader }
