const variants = {
  wipe: {
    hidden: {
      scaleY: 1,
    },
    visible: {
      scaleY: 0,
      transition: {
        delay: 0.75,
        duration: 1.25,
        ease: 'anticipate',
      },
    },
  },
  inner: {
    hidden: {
      y: 100,
    },
    visible: {
      y: 0,
      transition: {
        delay: 0.75,
        duration: 1.5,
        ease: 'anticipate',
      },
    },
  },
}

export default variants
