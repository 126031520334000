import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Section.styles.scss'

const Section = ({
  bgColor,
  children,
  className,
  paddingTop,
  paddingBottom,
  prev,
  next,
}) => {
  const classes = classNames(styles.el, className)

  return (
    <section
      className={classes}
      data-padding-top={paddingTop}
      data-padding-bottom={paddingBottom}
      data-next={next}
      data-prev={prev}
      data-bg-color={bgColor}
    >
      {children}
    </section>
  )
}

Section.defaultProps = {
  bgColor: 'black',
  children: undefined,
  className: undefined,
  paddingTop: true,
  paddingBottom: true,
  prev: undefined,
  next: undefined,
}

Section.propTypes = {
  bgColor: PropTypes.oneOf(['custom', 'white', 'black']),
  children: PropTypes.node,
  className: PropTypes.string,
  paddingTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  prev: PropTypes.string,
  next: PropTypes.string,
}

export { Section }
