/**
 * @param {Array} images
 */

import { useEffect, useState } from 'react'

// https://stackoverflow.com/a/14731922
const calculateFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
  return { width: srcWidth * ratio, height: srcHeight * ratio }
}

export const useFitContainer = (containerRef, width, height) => {
  const [size, setSize] = useState(0)

  const onResize = () => {
    if (containerRef.current !== null) {
      const containerWidth = containerRef.current.offsetWidth
      const containerHeight = containerRef.current.offsetHeight

      setSize(calculateFit(width, height, containerWidth, containerHeight))
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return size
}
